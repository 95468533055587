<template>
  <router-view />
</template>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

#nprogress .bar {
  background: red !important; //自定义颜色
  // 渐变背景色
  background: -moz-linear-gradient(
    left,
    rgb(255, 0, 60),
    rgb(50, 255, 228)
  ) !important;
  background: -ms-linear-gradient(
    left,
    rgb(255, 50, 98),
    rgb(50, 255, 228)
  ) !important;
  background: -o-linear-gradient(
    left,
    rgb(255, 50, 98),
    rgb(50, 255, 228)
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgb(255, 50, 98),
    rgb(50, 255, 228)
  ) !important;
}
</style>
