
import { Options, Vue } from "vue-class-component";
import { ScrollSpy } from "bootstrap";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
    ScrollSpy,
  },
  mounted() {
    this.navbarShrink();
    window.addEventListener("scroll", this.navbarShrink);

    // Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector("#mainNav");
    if (mainNav) {
      new ScrollSpy(document.body, {
        target: "#mainNav",
        offset: 74,
      });
    }

    // Collapse responsive navbar when toggler is visible
    // const navbarToggler = <any>document.body.querySelector(".navbar-toggler");
    // const responsiveNavItems = [].slice.call(
    //   document.querySelectorAll("#navbarResponsive .nav-link")
    // );
    // responsiveNavItems.map(function (responsiveNavItem) {
    //   responsiveNavItem.addEventListener("click", () => {
    //     if (window.getComputedStyle(navbarToggler).display !== "none") {
    //       navbarToggler.click();
    //     }
    //   });
    // });
  },
  methods: {
    navbarShrink() {
      const navbarCollapsible = document.body.querySelector("#mainNav");
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove("navbar-shrink");
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    },
  },
})
export default class Home extends Vue {}
